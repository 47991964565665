import domReady from '@wordpress/dom-ready';

domReady(() => {
  function animatePictures() {
    const firstWavePictures = document.querySelectorAll(
      '.picture-track--first-wave'
    );
    const secondWavePictures = document.querySelectorAll(
      '.picture-track--second-wave'
    );

    if (0 < firstWavePictures.length) {
      firstWavePictures.forEach((picture, index) => {
        const randomDuration = Math.floor(Math.random() * 15) + 60;
        const offset = 100 / firstWavePictures.length;
        const leftOffset = 95 - (offset * index + 1);

        picture.style.animationDuration = randomDuration + 's';
        picture.querySelector('.picture-wrapper').style.left = leftOffset + '%';
      });
    }

    if (0 < secondWavePictures.length) {
      secondWavePictures.forEach((picture, index) => {
        const randomDuration = Math.floor(Math.random() * 15) + 60;
        const offset = 100 / secondWavePictures.length;
        const leftOffset = 95 - (offset * index + 1);

        picture.style.animationDuration = randomDuration + 's';
        picture.querySelector('.picture-wrapper').style.left = leftOffset + '%';
      });
    }
  }

  function animateSuffixes() {
    const suffixes = document.querySelectorAll('.suffixes-wrapper > .suffix');

    if (0 < suffixes.length) {
      suffixes.forEach((suffix, index) => {
        const duration = 4 * suffixes.length;
        const delay = index * 4;
        suffix.style.animationDuration = duration + 's';
        suffix.style.animationDelay = delay + 's';
        suffix.style.opacity = '1';
      });
    }
  }

  animatePictures();
  animateSuffixes();
});
